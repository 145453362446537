<template>
  <div class="sms-page">
    SMS PAGE
  </div>
</template>

<script>
export default {
  name: 'Sms'
}
</script>

<style scoped>

</style>
